import React from "react";
import "./roadmap.css";
import step1 from "../images/steps/18.png";
import step2 from "../images/steps/19.png";
import step3 from "../images/steps/20.png";
import step4 from "../images/steps/21.png";
import step5 from "../images/steps/22.png";
import step6 from "../images/steps/23.png";
import Income from "../images/benefitsicons/highearning.png";

const Roadmap = () => {
  return (
    <>
      <div className="container benefit" id="benefits">
        <div className=" label-1">
          <h4 className="pt-5">ROADMAP</h4>
          <hr />
        </div>
        <div className="label-2">HOW TO BECOME A PILOT</div>

        <div className="roadmap col-lg-11 ">
          <div className="roadmap_box1 col-lg-6 Regular shadow">
            <div className="roadmap_image_box">
              <img className="roadmap_img" src={step1} alt="team image" />
            </div>
            <div className="roadmap_content">
              <h3>Class II Medical</h3>
              <p>
                A Class II Medical exam is a standard health assessment for
                pilots and air traffic controllers, ensuring their fitness for
                non-commercial flying activities. It assesses physical and
                mental fitness, including vision, hearing, and cardiovascular
                health..
              </p>
            </div>
          </div>

          <div className="roadmap_box2 col-lg-6 Regular shadow">
            <div className="roadmap_image_box">
              <img className="roadmap_img" src={step2} alt="team image" />
            </div>
            <div className="roadmap_content">
              <h3>Class I Medical + Computer Number Application</h3>
              <p>
                A Class I Medical in Aviation is an advanced health evaluation
                required for commercial pilots to ensure their fitness for duty.
                The Computer Number Application is a roll number used to manage
                and track pilots’ exam scores and medical certifications.
              </p>
            </div>
          </div>
        </div>

        <div className="roadmap2 col-lg-11 ">
          <div className="roadmap_box1 col-lg-6 Regular shadow">
            <div className="roadmap_image_box">
              <img className="roadmap_img" src={step3} alt="team image" />
            </div>
            <div className="roadmap_content">
              <h3>DGCA Ground Subjects </h3>
              <p>
                A Class II Medical exam is a standard health assessment for
                pilots and air traffic controllers, ensuring their fitness for
                non-commercial flying activities. It assesses physical and
                mental fitness, including vision, hearing, and cardiovascular
                health..
              </p>
            </div>
          </div>

          <div className="roadmap_box2 col-lg-6 Regular shadow">
            <div className="roadmap_image_box">
              <img className="roadmap_img" src={step4} alt="team image" />
            </div>
            <div className="roadmap_content">
              <h3>Flying Training </h3>
              <p>
                A Class I Medical in Aviation is an advanced health evaluation
                required for commercial pilots to ensure their fitness for duty.
                The Computer Number Application is a roll number used to manage
                and track pilots’ exam scores and medical certifications.
              </p>
            </div>
          </div>
        </div>

        <div className="roadmap3 col-lg-11 ">
          <div className="roadmap_box1 col-lg-6 Regular shadow">
            <div className="roadmap_image_box">
              <img className="roadmap_img" src={step5} alt="team image" />
            </div>
            <div className="roadmap_content">
              <h3>CPL License</h3>
              <p>
                A Class II Medical exam is a standard health assessment for
                pilots and air traffic controllers, ensuring their fitness for
                non-commercial flying activities. It assesses physical and
                mental fitness, including vision, hearing, and cardiovascular
                health..
              </p>
            </div>
          </div>

          <div className="roadmap_box2 col-lg-6 Regular shadow">
            <div className="roadmap_image_box">
              <img className="roadmap_img" src={step6} alt="team image" />
            </div>
            <div className="roadmap_content">
              <h3>Type Rating</h3>
              <p>
                A Class I Medical in Aviation is an advanced health evaluation
                required for commercial pilots to ensure their fitness for duty.
                The Computer Number Application is a roll number used to manage
                and track pilots’ exam scores and medical certifications.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
