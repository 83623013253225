// import React from "react";
// import "./flag.css";
// import Country1 from "../images/flags/india.png";
// import Country2 from "../images/flags/US.png";
// import Country3 from "../images/flags/South Africa_Flag 1.png";
// import Country4 from "../images/flags/Australia_Flag 1.png";
// import Country5 from "../images/flags/Newzealand_Flag 1.png";
// import Country6 from "../images/flags/Spain_Flag.png";
// import Country7 from "../images/flags/Canada_Flag 1.png";
// import Country8 from "../images/flags/Argentina_Flag 1.png";
// import Country9 from "../images/flags/Philippines_Flag 1.png";

// const Flag = () => {
//   return (
//     <div className="flag_box col-lg-12">
//       <div className="container">
//         <div className="label_flag">
//           We assist students to choose from different flying schools across the
//           globe:
//         </div>

//         <div className="main_flag_box">
//           <div className="inner_flag_box">
//             <img className="country_img" src={Country1} alt="team image" />
//             <p>INDIA</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country2} alt="team image" />
//             <p>UNITED STATES </p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country3} alt="team image" />
//             <p>SOUTH AFRICA</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country4} alt="team image" />
//             <p>AUSTRALIA</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country5} alt="team image" />
//             <p>NEW ZEALAND</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country6} alt="team image" />
//             <p>SPAIN</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country7} alt="team image" />
//             <p>CANADA</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country8} alt="team image" />
//             <p>ARGENTINA</p>
//           </div>

//           <div className="inner_flag_box2">
//             <img className="country2_img" src={Country9} alt="team image" />
//             <p>PHILIPPINES</p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Flag;

import React from "react";
import "./flag.css"; // Import your CSS file
import Country1 from "../images/flags/india.png";
import Country2 from "../images/flags/us.png";
import Country3 from "../images/flags/sf.png";
import Country4 from "../images/flags/AUSTRALIA.png";
import Country5 from "../images/flags/NEWZEALAND.png";
import Country6 from "../images/flags/SPAIN.png";
import Country7 from "../images/flags/CANADA.png";
import Country8 from "../images/flags/Argentina.png";
import Country9 from "../images/flags/Philippines.png";

const ImageGallery = () => {
  return (
    <div className="flag_box col-lg-12">
      <div className="container">
        <div className="label_flag">
          We assist students to choose from different flying schools across the
          globe:
        </div>

        <div className="container">
          <div className="image-gallery">
            <div className="image">
              <img className="country2_img" src={Country1} alt="team image" />
              <p>INDIA</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country2} alt="team image" />
              <p>UNITED STATES </p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country3} alt="team image" />
              <p>SOUTH AFRICA</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country4} alt="team image" />
              <p>AUSTRALIA</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country5} alt="team image" />
              <p>NEW ZEALAND</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country6} alt="team image" />
              <p>SPAIN</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country7} alt="team image" />
              <p>CANADA</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country8} alt="team image" />
              <p>ARGENTINA</p>
            </div>
            <div className="image">
              <img className="country2_img" src={Country9} alt="team image" />
              <p>PHILIPPINES</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGallery;
