import React, { useState } from "react";
import "./weoffer.css";

const Weoffer = () => {
  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);
  const [showMore5, setShowMore5] = useState(false);
  const [showMore6, setShowMore6] = useState(false);

  const handleReadMore = (section) => {
    switch (section) {
      case 1:
        setShowMore1(!showMore1);
        setShowMore2(false);
        setShowMore3(false);
        setShowMore4(false);
        setShowMore5(false);
        setShowMore6(false);
        break;
      case 2:
        setShowMore1(false);
        setShowMore2(!showMore2);
        setShowMore3(false);
        setShowMore4(false);
        setShowMore5(false);
        setShowMore6(false);
        break;
      case 3:
        setShowMore1(false);
        setShowMore2(false);
        setShowMore3(!showMore3);
        setShowMore4(false);
        setShowMore5(false);
        setShowMore6(false);
        break;
      case 4:
        setShowMore1(false);
        setShowMore2(false);
        setShowMore3(false);
        setShowMore4(!showMore4);
        setShowMore5(false);
        setShowMore6(false);
        break;
      case 5:
        setShowMore1(false);
        setShowMore2(false);
        setShowMore3(false);
        setShowMore4(false);
        setShowMore5(!showMore5);
        setShowMore6(false);
        break;
      case 6:
        setShowMore1(false);
        setShowMore2(false);
        setShowMore3(false);
        setShowMore4(false);
        setShowMore5(false);
        setShowMore6(!showMore6);
        break;
      default:
        setShowMore1(false);
        setShowMore2(false);
        setShowMore3(false);
        setShowMore4(false);
        setShowMore5(false);
        setShowMore6(false);
    }
  };

  return (
    <>
      <div className="container benefit" id="benefits">
        <div className="label-1 pt-5">
          <h4 className="pt-5">COURSES WE OFFER</h4>
          <hr />
        </div>
        <div className="label-2">
          TO OBTAIN A CPL, APPLICANTS MUST GAIN FLYING EXPERIENCE AND
          SUCCESSFULLY PASS A SERIES OF TESTS AND EXAMINATIONS.
        </div>

        <div className="weoffer_box col-lg-11 ">
          <div className="weoffer_box1 col-lg-4 Regular shadow">
            <div className="weoffer_social_content">
              <h3>AVIATION METEOROLOGY 1 Month</h3>
              <p>
                {showMore1
                  ? "Aspiring commercial pilots are also obligated to attend Aviation Meteorology classes, which cover essential topics like aviation weather theory, flight planning, and aviation weather services. These classes blend theoretical knowledge with practical experience, so once students work for airlines, they have to analyse the weather everytime. "
                  : "Aspiring commercial pilots are also obligated to attend Aviation Meteorology classes, which cover essential topics like aviation weather theory, flight planning"}
              </p>
              <div className="read_button">
                <button
                  onClick={() => handleReadMore(1)}
                  className="read-more-button"
                >
                  {showMore1 ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className="weoffer_box2 col-lg-4 Regular shadow">
            <div className="weoffer_social_content">
              <h3>AIR NAVIGATION 2 Month</h3>
              <p>
                {showMore2
                  ? "The air navigation course provides comprehensive instruction in crucial areas including aircraft performance, air regulations, navigation and communication systems, flight planning, instruments workings and errors, and understanding of modern radio equipments. As the subject name suggests, it deals with complete flight navigation from take off to landing."
                  : "The air navigation course provides comprehensive instruction in crucial areas including aircraft performance, air regulations, navigation and communication systems"}
              </p>
              <div className="read_button">
                <button
                  onClick={() => handleReadMore(2)}
                  className="read-more-button"
                >
                  {showMore2 ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className="weoffer_box2 col-lg-4 Regular shadow">
            <div className="weoffer_social_content">
              <h3>AIR REGULATIONS 1 Month</h3>
              <p>
                {showMore3
                  ? "In India, the Directorate General of Civil Aviation (DGCA) sets rules for Commercial Pilot License (CPL) Air Regulations Classes. These rules are essential for ensuring the safety and quality of airplanes and improve competency of students as a responsible pilots. It talks about International and national Civil Aviation Requirements and helps students to learn about their flying freedoms and limitations."
                  : "In India, the Directorate General of Civil Aviation (DGCA) sets rules for Commercial Pilot License (CPL) Air Regulations Classes."}
              </p>
              <div className="read_button">
                <button
                  onClick={() => handleReadMore(3)}
                  className="read-more-button"
                >
                  {showMore3 ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="weoffer_box col-lg-11 ">
          <div className="weoffer_box1 col-lg-4 Regular shadow">
            <div className="weoffer_social_content">
              <h3>TECHNICAL GENERAL 2 Month</h3>
              <p>
                {showMore4
                  ? "To obtain this license, aspiring pilots need to complete aircraft’s technical and general training classes. Technical training covers aircraft systems, performance, engines, electricals, flight principle and operations. This training equips pilots with the applicable theoretical knowledge needed to operate aircraft efficiently and safely."
                  : "To obtain this license, aspiring pilots need to complete aircraft’s technical and general training classes. Technical training covers aircraft systems, performance, engines"}
              </p>
              <div className="read_button">
                <button
                  onClick={() => handleReadMore(4)}
                  className="read-more-button"
                >
                  {showMore4 ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className="weoffer_box2 col-lg-4 Regular shadow">
            <div className="weoffer_social_content">
              <h3>RADIO TELEPHONY PART I & II 1 Month</h3>
              <p>
                {showMore5 ? (
                  <span>
                    The Radio Telephony Restricted License (RTR) exam is a radio
                    communication skill test for pilots. The exam is conducted
                    by the Ministry of Communication's Wireless Planning &
                    Coordination (WPC) wing. The exam assesses whether pilots
                    are trained to communicate with Air Traffic Controllers
                    (ATCs).
                    <br />
                    The RTR exam has two sections:
                    <br />
                    1. Practical examination (Transmission):- Covers
                    transmitting techniques, regulations, and procedures.
                    <br />
                    2. Viva Exam (Oral):- Covers radio principles and practice,
                    regulations, and procedures.
                  </span>
                ) : (
                  "The Radio Telephony Restricted License (RTR) exam is a radio communication skill test for pilots. The exam is conducted by the Ministry of Communication's Wireless"
                )}
              </p>

              <div className="read_button">
                <button
                  onClick={() => handleReadMore(5)}
                  className="read-more-button"
                >
                  {showMore5 ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>

          <div className="weoffer_box2 col-lg-4 Regular shadow">
            <div className="weoffer_social_content">
              <h3>BOOSTER COURSE</h3>
              <p>
                {showMore6
                  ? "Depends on the subject. It is at almost one-fourth the regular course fee. All classes are of 1.5 - 2 hours or more. Only for those who have covered at least 60% of the syllabus. Any topic from anywhere can be discussed with expectation of concept explanation as well. We also make the student walk through all the important sections of all the chapters in any subject. The number of days can be further extended but cannot be reduced than the given limit days. Tests &  solution explanations are also provided once you are confident about the chapters and all the concepts.It is an under priced course for maximum benefit of those students who are unable to pass or are not gaining confidence even after self study/taking top coachings."
                  : "Depends on the subject. It is at almost one-fourth the regular course fee. All classes are of 1.5 - 2 hours or more. Only for those who have covered at least"}
              </p>
              <div className="read_button">
                <button
                  onClick={() => handleReadMore(6)}
                  className="read-more-button"
                >
                  {showMore6 ? "Read Less" : "Read More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Weoffer;
