// import React from "react";
// import "./contact.css";
// import { TfiEmail } from "react-icons/tfi";
// import { BsPhone } from "react-icons/bs";
// import emailjs from "@emailjs/browser";
// import { useRef } from "react";

// const Contact = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs
//       .sendForm(
//         "service_ol46eif",
//         "template_kz2kwhq",
//         form.current,
//         "eurSS5woWB2TCAJr_"
//       )
//       .then(
//         (result) => {
//           console.log(result.text);
//         },
//         (error) => {
//           console.log(error.text);
//         }
//       );
//   };

//   return (
//     <>
//       <div className="container" id="contact">
//         <div className="label-1 pt-5">
//           <h4 className="pt-5">CONTACT</h4>
//           <hr />
//         </div>
//         <div className="label-2">CONTACT US</div>

//         <div className="contact_box col-lg-11">
//           <div className="contact col-lg-4">
//             <div className="contact_box1 col-lg-12 pt-5">
//               <div className="inner_contact_box1 col-lg-2">
//                 <TfiEmail className="contact_icon" />
//               </div>
//               <div className="inner_contact_box2">
//                 <h4>Email:</h4>
//                 <p>info@pilotpreparationacademy.com</p>
//               </div>
//             </div>

//             <div className="contact_box1 col-lg-12 pt-5">
//               <div className="inner_contact_box1 col-lg-2">
//                 <BsPhone className="contact_icon" />
//               </div>
//               <div className="inner_contact_box2">
//                 <h4>Call:</h4>
//                 <p>
//                   +91-9301657163 (Rishi Chaudhary)
//                   <p className="mx-0">+91-9718200388 (Jitender Bhati)</p>
//                 </p>
//               </div>
//             </div>
//           </div>

//           <form className="col-lg-9" ref={form} onSubmit={sendEmail}>
//             <div className="input_name_email">
//               <input
//                 type="text"
//                 name="first_name"
//                 className="input_name"
//                 placeholder="Your Name"
//                 required
//               />
//               <input
//                 type="email"
//                 name="user_email"
//                 className="input_email"
//                 placeholder="Your Email"
//                 required
//               />
//             </div>

//             <input
//               type="text"
//               name="user_subject"
//               className="input_subject col-lg-12"
//               placeholder="Subject"
//               required
//             />
//             <textarea
//               type="desc"
//               name="user_message"
//               className="input_message col-lg-12"
//               placeholder="Message"
//               required
//             />

//             <div className="contact_btn">
//               <button className="send_btn" type="submit" value="Send">
//                 Send Message
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Contact;

import React, { useRef } from "react";
import "./contact.css";
import { TfiEmail } from "react-icons/tfi";
import { BsPhone } from "react-icons/bs";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ol46eif",
        "template_kz2kwhq",
        form.current,
        "eurSS5woWB2TCAJr_"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message sent successfully!");
          clearFormInputs();
        },
        (error) => {
          console.log(error.text);
          toast.error("Email sending failed. Please try again.");
        }
      );
  };

  const clearFormInputs = () => {
    form.current.reset();
  };

  return (
    <>
      <div className="container" id="contact">
        <div className="label-1 ">
          <h4 className="pt-5">CONTACT</h4>
          <hr />
        </div>
        <div className="label-2">CONTACT US</div>

        <div className="contact_box col-lg-11">
          <div className="contact col-lg-4">
            <div className="contact_box1 col-lg-12 pt-5">
              <div className="inner_contact_box1 col-lg-2">
                <TfiEmail className="contact_icon" />
              </div>
              <div className="inner_contact_box2">
                <h4>Email:</h4>
                <p>info@pilotpreparationacademy.com</p>
              </div>
            </div>

            <div className="contact_box1 col-lg-12 pt-5">
              <div className="inner_contact_box1 col-lg-2">
                <BsPhone className="contact_icon" />
              </div>
              <div className="inner_contact_box2">
                <h4>Call:</h4>
                <p>+91-9301657163 (Rishi Chaudhary)</p>
              </div>
            </div>
          </div>

          <form className="col-lg-9" ref={form} onSubmit={sendEmail}>
            <div className="input_name_email">
              <input
                type="text"
                name="first_name"
                className="input_name"
                placeholder="Your Name"
                required
              />
              <input
                type="email"
                name="user_email"
                className="input_email"
                placeholder="Your Email"
                required
              />
            </div>

            <input
              type="text"
              name="user_subject"
              className="input_subject col-lg-12"
              placeholder="Enter a 10-digit number"
              oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)"
              maxlength="10"
              required
            />
            <textarea
              type="desc"
              name="user_message"
              className="input_message col-lg-12"
              placeholder="Message"
              required
            />

            <div className="contact_btn">
              <button className="send_btn" type="submit" value="Send">
                Send Message
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Contact;
