import React from "react";
import "./eligibility.css";
import { RxDoubleArrowRight } from "react-icons/rx";
import Pilot from "../images/pilot.jpg";
import Best from "../images/best.jpg";

const Eligibility = () => {
  return (
    <>
      <div className="container" id="eligibility">
        <div className="label-1">
          <h4 className="pt-5">ELIGIBILITY</h4>
          <hr />
        </div>
        <div className="label-2">WHAT IT TAKES TO BE A PILOT?</div>
        <div className="eligibility_box col-lg-12 pt-5">
          <div className="eligibility_box_inner col-lg-5">
            <img className="eligibility_img" src={Pilot} alt="team image" />
          </div>
          <div className="eligibility_box_inner2 col-lg-6">
            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;Your age should not be less than 17 years to begin training.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;You must have scored 50% in 10+2 which may vary as per the
              Institute’s requirement.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;You must have studied MPC subjects [Maths, Physics, and
              Chemistry] along with English at the intermediate level.
            </p>
            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;If you are a non-science student, you may pursue the
              required subjects through the National Institute of Open Schooling
              or as a private candidate from the respective state board. We
              assist students completely with that as well.
            </p>
            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;You will need a medical certificate issued by the required
              authorities.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Eligibility;
