import React from "react";
import "./whyus.css";
import { RxDoubleArrowRight } from "react-icons/rx";
import Best from "../images/best.jpg";

const WhyUs = () => {
  return (
    <>
      <div className="container pt-5" id="whyus">
        <div className="label-1">
          <h4 className="pt-5">WHY US</h4>
          <hr />
        </div>
        <div className="label-2">LEARN FROM THE BEST</div>
        <div className="whyus_main col-lg-12 pt-4">
          <div className="whyus_inner1 col-lg-7">
            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Hardworking :</b> We devote our heart and soul to bring
              successful results.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Doubt Free Learning :</b> Students are encouraged to ask
              any number of doubts.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Fun :</b> The pace of the class is synchronous and
              entertaining.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Homework :</b> H.W. is religiously given everyday! and
              discussed the next day.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Best Deal :</b> We provide both quality and affordability
              in the same platter.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Re- Study :</b> Students are allowed to re-join us for
              doubtful topics for free.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Always Available :</b> We assist students until they
              pass.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>On Time Completion :</b> Syllabus is completed for each
              student on time.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Fee in Breaks :</b> The fee can be paid in installments.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Action on Feedback :</b> We adapt with student’s
              feedback.
            </p>

            <p>
              <RxDoubleArrowRight className="arrow_icon" />
              &nbsp;<b>Connections :</b> Having some industry connections we
              also provide best career support.
            </p>
          </div>
          <div className="whyus_inner2 col-lg-4">
            <div className="pilot whyus_img_box ratio-16x9">
              <img className="pilot_img2" src={Best} alt="team image" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyUs;
